/**
 *
 * Initial selection configuration for optional features
 * These are additional fields, don't need validation.
 *
 **/

export const initialSelection = {
  basic_fields: {
    sorts: 0,
    premium_services: [],
    additional_forms: 0,
    high_priority: false, // Static field. Start with `false`.
    package_type: ''
  },
  briefing_fields: {
    project_name: '',
    description: '',
    sample_form: false,
    fonts: {
      font_style: '',
      font_type: ''
    },
    target_audience: {
      target_gender: 5,
      target_income: 5,
      target_age: 5
    },
    label_style: {
      style_type: 5,
      design_complexity: 5,
      gender_orientation: 5,
      tone: 5,
      luxury_level: 5,
      coloration: 5,
      concept: 5
    },
    color_preference: {
      primary_color: '',
      secondary_color: ''
    },
    color_rule: '',
    specific_fonts: false,
    packaging: '',
    target_audience_gernder: '',
    reference_link: '',
    material_suggestion: false,
    deadline: '',
    finishing: 114,
    width: 0,
    height: 0,
    label_shape: 0,
    paper: 0,
    asset_id: []
  }
}

/**
 *
 * basic_fields_response: First form (basic_field submit response)
 *
 **/
export const basic_fields_response = {}

/**
 *
 * step
 *
 **/
export const step = 1

/**
 *
 * ds_price: initial ds_price and payload object.
 *
 **/
export const ds_prices = {
  basic_fields: {
    sorts: 0,
    premium_services: 0,
    additional_forms: 0,
    package_type: '',
    total: 0
  }
}

/**
 *
 * basic_fields.
 *
 **/
export const basic_fields = 'basic_fields'
/**
 *
 * basic_fields.
 *
 **/
export const briefing_fields = 'briefing_fields'

/**
 *
 * highPriority.
 *
 **/
export const highPriority = 'high_priority'
/**
 *
 * Total field.
 *
 **/
export const total = 'total'
/**
 *
 * Total field.
 *
 **/
export const asset_id = 'asset_id'
/**
 *
 * Package field.
 *
 **/
// export const packageType = 'package_price'
export const packageType = 'package_type'
/**
 *
 * Package field.
 *
 **/
export const agreementField = 'agreement'

/**
 *
 * Package selection is static (Not fetching from API).
 *
 **/

export const packageSelection = {
  en: [
    { title: 'Select', value: 'basic' },
    { title: 'Select', value: 'silver' },
    { title: 'Select', value: 'gold' }
  ],
  es: [
    { title: 'Select', value: 'basic' },
    { title: 'Select', value: 'silver' },
    { title: 'Select', value: 'gold' }
  ]
}

/**
 * Brief page extra fields not mapped in API.
 */

export const briefPageExtraFields = [
  {
    title: 'Suggestion Address',
    type: 'textarea',
    key: 'suggestion_address',
    options: [
      {
        name: 'Suggestion Address',
        Placeholder: 'Our address is: etikett.de Europa-Allee 21 54343 Föhren / Germany Design Service / Marketing',
        value: ''
      }
    ]
  }
]

/**
 * Authorization
 */

export const authorizationField = {
  title: 'Authorization',
  description: '',
  type: 'radio',
  key: 'authorization',
  options: [
    {
      name: 'yes',
      key: 'yes',
      label: 'Yes',
      value: 'Yes'
    },
    {
      name: 'no',
      key: 'no',
      label: 'No',
      value: 'No'
    }
  ]
}
