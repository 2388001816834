import { IRoutes, ILanguages } from './types'

export const ROUTES: Record<IRoutes, ILanguages> = {
  [IRoutes.CALCULATOR]: {
    es: 'calculadora',
    en: 'calculator'
  },
  [IRoutes.HOME]: {
    es: 'inicio',
    en: 'home'
  },
  [IRoutes.ABOUT_US]: {
    es: 'sobre-nosotros',
    en: 'about-us'
  },
  [IRoutes.LOGIN]: {
    es: 'acceso',
    en: 'login'
  },
  [IRoutes.SIGNUP]: {
    es: 'inscribirse',
    en: 'signup'
  },
  [IRoutes.FORGOT_PASSWORD]: {
    es: 'se-te-olvido-tu-contrasena',
    en: 'forgot-password'
  },
  [IRoutes.PRIVACY_POLICY]: {
    es: 'politica-de-privacidad',
    en: 'privacy-policy'
  },
  [IRoutes.CONTACT]: {
    es: 'contacto',
    en: 'contact'
  },
  [IRoutes.GLOSSARY]: {
    es: 'glosario',
    en: 'glossary'
  },
  [IRoutes.TUTORIALS]: {
    es: 'tutoriales',
    en: 'tutorials'
  },
  [IRoutes.JOBS]: {
    es: 'empleos',
    en: 'jobs'
  },
  [IRoutes.FAQ]: {
    es: 'preguntas-mas-frecuentes',
    en: 'customer-service-faq'
  },
  [IRoutes.MATERIALS]: {
    es: 'materiales',
    en: 'materials'
  },
  [IRoutes.EXPRESS_PRODUCTION]: {
    es: 'produccion-expresa-v2',
    en: 'produccion-expresa-v2'
  },
  [IRoutes.DELIVERY_SHIPPING]: {
    es: 'gastos-de-envio-y-entrega',
    en: 'delivery-and-shipping-costs'
  },
  [IRoutes.FINISHING_TOUCHES]: {
    es: 'acabados-finales',
    en: 'finishing-touches'
  },
  [IRoutes.REFERENCE_CUSTOMERS]: {
    es: 'clientes',
    en: 'reference-customers'
  },
  [IRoutes.CUSTOMERS_STORIES]: {
    es: 'historias-de-clientes',
    en: 'customer-stories'
  },
  [IRoutes.NEWS]: {
    es: 'noticias',
    en: 'news'
  },
  [IRoutes.PRESS]: {
    es: 'prensa',
    en: 'press'
  },
  [IRoutes.LABEL_GALLERY]: {
    es: 'galeria-de-etiquetas',
    en: 'label-gallery'
  },
  [IRoutes.CATEGORIES]: {
    es: 'categorias',
    en: 'categories'
  },
  [IRoutes.PRODUCTS]: {
    es: 'productos',
    en: 'products'
  },
  [IRoutes.PROOFING_SERVICE]: {
    es: 'prueba-de-impresion',
    en: 'proofing-service'
  },
  [IRoutes.ARTWORK_SERVICES]: {
    es: 'servicios-de-revision',
    en: 'artwork-services'
  },
  [IRoutes.PRINT_DATA_DELIVERY]: {
    es: 'entrega-de-archivos-de-impresion',
    en: 'print-data-delivery'
  },
  [IRoutes.SAMPLE_BOOK]: {
    es: 'muestrario',
    en: 'sample-book'
  },
  [IRoutes.WORLD_OF]: {
    es: 'mundo-de',
    en: 'world-of'
  },
  [IRoutes.ARTWORK_ASSISTANT]: {
    es: 'crea-tu-archivo',
    en: 'artwork-assistant'
  },
  [IRoutes.DESIGN_SERVICE]: {
    es: 'servicios-de-diseno',
    en: 'design-service'
  },
  [IRoutes.SIGNUP_SUCCESS]: {
    es: 'registro-completo',
    en: 'signup-success'
  },
  [IRoutes.TRANSPARENCY_INFO]: {
    es: 'informacion-de-transparencia',
    en: 'transparency-info'
  },
  [IRoutes.CART]: {
    es: 'cesta',
    en: 'cart'
  },
  [IRoutes.LEGAL_WARNING]: {
    es: 'aviso-legal',
    en: 'legal-warning'
  },
  [IRoutes.CONDITIONS_OF_SALE]: {
    es: 'condiciones-generales-de-venta',
    en: 'general-conditions-of-sale'
  },
  [IRoutes.PAGE_NOT_FOUND]: {
    es: 'pagina-no-encontrada',
    en: 'page-not-found'
  },
  [IRoutes.PRE_PRINT_ARTWORK]: {
    es: 'pre-print-artwork',
    en: 'pre-print-artwork'
  },
  [IRoutes.UPLOAD_FILES]: {
    es: 'upload-files',
    en: 'upload-files'
  },
  [IRoutes.MY_ACCOUNT]: {
    es: 'mi-cuenta',
    en: 'my-account'
  },
  [IRoutes.MANAGE_CREDENTIALS]: {
    es: 'administrar-credenciales',
    en: 'manage-credentials'
  },
  [IRoutes.PROOF_ORDER]: {
    es: 'prueba-de-pedido',
    en: 'proof-order'
  },
  [IRoutes.CUSTOMER_REGISTERED]: {
    es: 'cliente-registrado',
    en: 'customer-registered'
  },
  [IRoutes.MY_ORDERS]: {
    es: '/mi-cuenta/ordenes',
    en: '/my-account/orders'
  },
  [IRoutes.ARTWORK_SERVICES_REVIEW_INFO]: {
    es: 'artwork-service-revision',
    en: 'artwork-service-revision'
  },
  [IRoutes.ERROR_PAGE]: {
    es: 'pagina-de-errores',
    en: 'pagina-de-errores'
  },
  [IRoutes.SUSTAINABILITY]: {
    es: 'sostenibilidad',
    en: 'sustainability'
  },
  [IRoutes.ORDER_ARTWORK_SERVICE]: {
    es: 'encargar-servicios-de-revision',
    en: 'order-artwork-service'
  },
  [IRoutes.SAMPLE_SEARCH]: {
    es: 'muestra-de-busqueda',
    en: 'sample-search'
  }
}

const createRouteList = (routes: Object) => {
  const list = {}

  Object.values(routes).forEach((item) => {
    list[`/${item.es}`] = `/${item.en}`
  })

  return list
}

export const routeList = createRouteList(ROUTES)

export const ROUTER_PATH: Record<IRoutes, ILanguages> = {
  [IRoutes.CALCULATOR]: {
    es: '/calculadora',
    en: '/calculator'
  },
  [IRoutes.HOME]: {
    es: '/',
    en: '/'
  },
  [IRoutes.ABOUT_US]: {
    es: '/sobre-nosotros',
    en: '/about-us'
  },
  [IRoutes.LOGIN]: {
    es: '/acceso',
    en: '/login'
  },
  [IRoutes.SIGNUP]: {
    es: '/inscribirse',
    en: '/signup'
  },
  [IRoutes.FORGOT_PASSWORD]: {
    es: '/se-te-olvido-tu-contrasena',
    en: '/forgot-password'
  },
  [IRoutes.PRIVACY_POLICY]: {
    es: '/politica-de-privacidad',
    en: '/privacy-policy'
  },
  [IRoutes.CONTACT]: {
    es: '/contacto',
    en: '/contact'
  },
  [IRoutes.GLOSSARY]: {
    es: '/glosario',
    en: '/glossary'
  },
  [IRoutes.TUTORIALS]: {
    es: '/tutoriales',
    en: '/tutorials'
  },
  [IRoutes.JOBS]: {
    es: '/empleos',
    en: '/jobs'
  },
  [IRoutes.FAQ]: {
    es: '/preguntas-mas-frecuentes',
    en: '/customer-service-faq'
  },
  [IRoutes.MATERIALS]: {
    es: '/materiales',
    en: '/materials'
  },
  [IRoutes.EXPRESS_PRODUCTION]: {
    es: '/produccion-expresa',
    en: '/express-production'
  },
  [IRoutes.DELIVERY_SHIPPING]: {
    es: '/gastos-de-envio-y-entrega',
    en: '/delivery-and-shipping-costs'
  },
  [IRoutes.FINISHING_TOUCHES]: {
    es: '/acabados-finales',
    en: '/finishing-touches'
  },
  [IRoutes.REFERENCE_CUSTOMERS]: {
    es: '/clientes',
    en: '/reference-customers'
  },
  [IRoutes.CUSTOMERS_STORIES]: {
    es: '/historias-de-clientes',
    en: '/customer-stories'
  },
  [IRoutes.NEWS]: {
    es: '/noticias',
    en: '/news'
  },
  [IRoutes.PRESS]: {
    es: '/prensa',
    en: '/press'
  },
  [IRoutes.LABEL_GALLERY]: {
    es: '/galeria-de-etiquetas',
    en: '/label-gallery'
  },
  [IRoutes.CATEGORIES]: {
    es: '/categorias',
    en: '/categories'
  },
  [IRoutes.PRODUCTS]: {
    es: '/productos',
    en: '/products'
  },
  [IRoutes.PROOFING_SERVICE]: {
    es: '/prueba-de-impresion',
    en: '/proofing-service'
  },
  [IRoutes.ARTWORK_SERVICES]: {
    es: '/servicios-de-revision',
    en: '/artwork-services'
  },
  [IRoutes.PRINT_DATA_DELIVERY]: {
    es: '/entrega-de-archivos-de-impresion',
    en: '/print-data-delivery'
  },
  [IRoutes.SAMPLE_BOOK]: {
    es: '/muestrario',
    en: '/sample-book'
  },
  [IRoutes.WORLD_OF]: {
    es: '/mundo-de',
    en: '/world-of'
  },
  [IRoutes.ARTWORK_ASSISTANT]: {
    es: '/crea-tu-archivo',
    en: '/artwork-assistant'
  },
  [IRoutes.DESIGN_SERVICE]: {
    es: '/servicios-de-diseno',
    en: '/design-service'
  },
  [IRoutes.SIGNUP_SUCCESS]: {
    es: '/registro-completo',
    en: '/signup-success'
  },
  [IRoutes.TRANSPARENCY_INFO]: {
    es: '/informacion-de-transparencia',
    en: '/transparency-info'
  },
  [IRoutes.CART]: {
    es: '/cesta',
    en: '/cart'
  },
  [IRoutes.LEGAL_WARNING]: {
    es: '/aviso-legal',
    en: '/legal-warning'
  },
  [IRoutes.CONDITIONS_OF_SALE]: {
    es: '/condiciones-generales-de-venta',
    en: '/general-conditions-of-sale'
  },
  [IRoutes.PAGE_NOT_FOUND]: {
    es: '/pagina-no-encontrada',
    en: '/page-not-found'
  },
  [IRoutes.PRE_PRINT_ARTWORK]: {
    es: '/pre-print-artwork',
    en: '/pre-print-artwork'
  },
  [IRoutes.UPLOAD_FILES]: {
    es: '/upload-files',
    en: '/upload-files'
  },
  [IRoutes.MY_ACCOUNT]: {
    es: '/mi-cuenta',
    en: '/my-account'
  },
  [IRoutes.MY_ORDERS]: {
    es: '/mi-cuenta/ordenes',
    en: '/my-account/orders'
  },
  [IRoutes.MANAGE_CREDENTIALS]: {
    es: 'administrar-credenciales',
    en: 'manage-credentials'
  },
  [IRoutes.PROOF_ORDER]: {
    es: '/prueba-de-pedido',
    en: '/proof-order'
  },
  [IRoutes.CUSTOMER_REGISTERED]: {
    es: '/cliente-registrado',
    en: '/customer-registered'
  },
  [IRoutes.ARTWORK_SERVICES_REVIEW_INFO]: {
    es: '/artwork-service-revision',
    en: '/artwork-service-revision'
  },
  [IRoutes.ERROR_PAGE]: {
    es: '/error-page',
    en: '/error-page'
  },
  [IRoutes.SUSTAINABILITY]: {
    es: '/sostenibilidad',
    en: '/sustainability'
  },
  [IRoutes.ORDER_ARTWORK_SERVICE]: {
    es: '/encargar-servicios-de-revision',
    en: '/order-artwork-service'
  },
  [IRoutes.SAMPLE_SEARCH]: {
    es: '/muestra-de-busqueda',
    en: '/sample-search'
  }
}
